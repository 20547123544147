import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        noPic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "date": "2017-04-19",
  "title": "How to Pin an Element to the Center of its Parent",
  "description": "Centering in CSS",
  "cover": "/img/cover/pinned-center.png",
  "noPic": true,
  "color": "#889d9a"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Centering things in CSS can sometimes seem impossible. Especially if the child to be centered has a dynamic width or height, or is actually larger than its parent element.`}</p>
    <p>{`Pinning a child element to the center of its parent has a lot of uses. While building the zombie bones in `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com"
      }}>{`my game`}</a>{` I needed a way for the container (parent element) to take up a specific height and width regardless of the size of its content, and have the animation canvas (child element) be centered perfectly even though it's larger than its parent. This allows the zombies in my game to take up a predictable space, but let their animations bleed outside the set space without getting clipped.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/pinning-center.png",
        "alt": "pinning element to parent's center"
      }}></img></p>
    <p>{`Luckily for us, this is actually pretty easy with a little flexbox magic!`}</p>
    <h2>{`Pin Child to Parent`}</h2>
    <p>{`On the parent element all we have to do is:`}</p>
    <ol>
      <li parentName="ol">{`set the parent container element to use flexbox`}</li>
      <li parentName="ol">{`center its children horizontally with `}<inlineCode parentName="li">{`justify-content`}</inlineCode></li>
      <li parentName="ol">{`center its children vertically with `}<inlineCode parentName="li">{`align-items`}</inlineCode></li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
`}</code></pre>
    <p>{`One important thing not to miss on the child element is to `}<strong parentName="p">{`tell it not to shrink`}</strong>{`. Otherwise the child will squish down to the width of its parent instead of staying its full width like we want.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.child-canvas {
  flex-shrink: 0;
}
`}</code></pre>
    <p>{`Here is an example you can play with:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .parent{
    width: 200px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px dashed #6BD9ED;
  }\n
  .child{
    width: 300px;
    height: 400px;
    flex-shrink: 0;
    border: 5px dashed #A7E040;
  }
`} fixedCSS={`
  body{
    margin: 100px;
  }   
`} html={`
  <div class="parent">
    <div class="child"></div>
  </div>   
`} mdxType="CodeExample" />
    <h2>{`Shifting the Position of the Child`}</h2>
    <p>{`Once you have this setup, you can easily make adjustments to the position of the child element. For example if you'd rather you can pin it to the top of the parent:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
.parent{
  width: 200px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: 5px dashed #6BD9ED;
}\n
.child{
  width: 300px;
  height: 400px;
  flex-shrink: 0;
  border: 5px dashed #A7E040;
}
`} fixedCSS={`
  body {
  margin-bottom: 100px;
}     
`} html={`
<div class="parent">
  <div class="child"></div>
</div>   
`} mdxType="CodeExample" />
    <p>{`Or pin it to the bottom left:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .parent{
    width: 200px;
    height: 300px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    border: 5px dashed #6BD9ED;
  }\n
  .child{
    width: 300px;
    height: 400px;
    flex-shrink: 0;
    border: 5px dashed #A7E040;
  }
`} fixedCSS={`
  body {
  margin-top: 100px;
}     
`} html={`
<div class="parent">
  <div class="child"></div>
</div>   
`} mdxType="CodeExample" />
    <p>{`Even after you've pinned it, you can make tiny little adjustments using `}<inlineCode parentName="p">{`relative`}</inlineCode>{` positioning on the child to get it just where you want it:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
  .parent{
    width: 200px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 5px dashed #6BD9ED;
  }\n
  .child{
    width: 300px;
    height: 400px;
    flex-shrink: 0;
    border: 5px dashed #A7E040;
    position: relative;
    right: -15px
  }
`} fixedCSS={`
  body {
  margin-bottom: 50px;
  margin-top: 50px;
}     
`} html={`
<div class="parent">
  <div class="child"></div>
</div>   
`} mdxType="CodeExample" />
    <h2>{`Sweet right?`}</h2>
    <p>{`Now that you know how to pin elements to their parents you can center layouts quite easily. Flexbox is super powerful for this and many other UI needs. `}<a parentName="p" {...{
        "href": "https://flexboxzombies.com/"
      }}>{`Flexbox Zombies`}</a>{` covers it all in gritty (gory?) detail!`}</p>
    <p>{`Here's your homework: Go play with the codepen examples above and look for a way to use this to simplify one of your existing centering hacks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      